.custom-button {
  padding: 11px 38px !important;
  background-color: #369D8C !important;
  text-transform: none !important;
  color: #fff !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  &:disabled {
    background-color: #f5f5f5 !important;
    border: 1px solid #ebebeb !important;
    color: #d3d3d3 !important;
    cursor: default;
    box-shadow: none;
  }
  &._grey {
    background-color: #AFAFAF !important;
    color: #3D3D3D !important;
  }
}