.custom-modal {
  &__close-button {
    position: absolute !important;
    top: 10px;
    right: 10px;
    z-index: 2;
    color: #fff !important;
    padding: 6px !important;
    @media (max-width: 460px) {
      top: 6px;
      right: 6px;
    }
  }
  &__top {
    width: 100% !important;
    padding: 16px 0 !important;
    background-color: #369D8C !important;
    box-shadow: none !important;
    border-radius: 5px 5px 0 0 !important;
    @media (max-width: 460px) {
      padding: 12px 0 !important;
    }
  }
  &__title {
    min-height: auto !important;
    padding: 0 20px !important;
    color: #fff !important;
  }
  &__content {
    padding: 24px 20px !important;
    @media (max-width: 460px) {
      padding: 16px 12px !important;
    }
  }
  .MuiDialog-paper {
    width: 100%;
    min-width: 500px;
    border-radius: 4px;
    margin: 40px 24px 24px;
    padding: 0;
    overflow: visible;
    @media (max-width: 600px) {
      min-width: auto;
      margin: 12px;
    }
    @media (max-width: 460px) {
      max-width: calc(100% - 24px) !important;
    }
  }
}