.footer {
  background-color: #006655;
  padding: 18px 0;
  &__wrap {
    display: flex;
    align-items: center;
    @media (max-width: 460px) {
      display: block;
    }
  }
  &__socials-list {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 460px) {
      justify-content: center;
    }
  }
  &__socials-item {
    &:not(:last-child) {
      margin-right: 17px;
    }
    .MuiSvgIcon-root {
      line-height: 1;
      height: 18px !important;
      width: auto !important;
      color: #fff !important;
    }
  }
  &__text {
    font-size: 14px;
    color: #fff;
    &:last-child {
      text-align: right;
      margin-left: auto;
    }
    @media (max-width: 460px) {
      margin: 0;
      text-align: center !important;
      &:first-child {
        margin-bottom: 8px;
      }
    }
    &-link {
      color: #fff;
    }
  }
}