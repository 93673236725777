.loading {
  display: block;
  width: 40px;
  margin: 0 auto;
  padding: 40px 0;
  &._fixed {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .MuiCircularProgress-colorPrimary {
    color: #369D8C;
  }
}