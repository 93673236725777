.header {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #006655;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    @media (max-width: 1085px) {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }
  &__links-list, &__socials-list {
    list-style: none;
    display: flex;
    align-items: center;
  }
  &__links-item {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-right: 46px;
    }
  }
  &__links-indicator {
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: red;
    border-radius: 50%;
    margin-right: 6px;
    &._active {
      background-color: lightgreen;
    }
  }
  &__link {
    font-size: 14px;
    line-height: 16px;
    color: #606060;
    cursor: pointer;
    &:hover {
      color: #006655;
      text-decoration: none;
    }
  }
  &__socials-item {
    line-height: 1;
    &:not(:last-child) {
      margin-right: 17px;
    }
    a {
      .MuiSvgIcon-root {
        line-height: 1;
        height: 18px !important;
        width: auto !important;
        color: #828282 !important;
      }
    }
  }
  &__menu {
    .MuiPaper-root {
      overflow: hidden;
    }
    .sidebar {
      overflow: hidden;
    }
    .menu-sidebar {
      display: flex;
      flex-direction: column;
      height: 100%;
      position: static !important;
      padding: 0 0 12px;
      overflow: hidden;
      &__top, &__list {
        padding: 0 12px;
      }
      &__list {
        overflow-y: auto;
      }
    }
  }
  &__menu-control {
    text-align: right;
  }
  &__menu-btn {
    margin-left: -10px !important;
  }
  &__modal {
    &-button {
      display: block !important;
      margin: 24px auto 0 !important;
    }
  }
}